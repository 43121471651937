const checkVisible = (target: Element, margin = 0) => {
  const targetPosition = {
    top: window.scrollY + target.getBoundingClientRect().top,
    bottom: window.scrollY + target.getBoundingClientRect().bottom,
  };
  const windowPosition = {
    top: window.scrollY,
    bottom: window.scrollY + document.documentElement.clientHeight,
  };

  return (
    targetPosition.bottom + margin > windowPosition.top &&
    targetPosition.top - margin < windowPosition.bottom
  );
};

const weakMap = new WeakMap();

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('show-on-visible', {
    created(el, binding) {
      const margin = binding.value?.margin || 0;
      const init = () => {
        el.style.transform = 'translateY(100px)';
        el.style.opacity = '0';
        el.style.transition = 'opacity 0.5s, transform 0.5s';
        const show = () => {
          if (checkVisible(el, margin)) {
            setTimeout(
              () => {
                el.style.transform = '';
                el.style.opacity = '';
                window.removeEventListener('scroll', weakMap.get(el));
                weakMap.delete(el);
              },
              ((!binding.value?.noDelayOnMobile ||
                window.innerWidth >= binding.value.noDelayOnMobile) &&
                binding.value?.delay) ||
                0,
            );
          }
        };
        weakMap.set(el, show);
        window.addEventListener('scroll', show);
        setTimeout(() => {
          if (weakMap.get(el)) weakMap.get(el)();
        }, 500);
      };
      if (!checkVisible(el)) {
        init();
      }
    },
    beforeUnmount(el) {
      window.removeEventListener('scroll', weakMap.get(el));
      weakMap.delete(el);
    },
  });
});
