import { default as about_45fantsilf2msTgv3Meta } from "/app/pages/index/about-fants.vue?macro=true";
import { default as _91slug_9343Fn77GEW3Meta } from "/app/pages/index/articles/[slug].vue?macro=true";
import { default as index7p970EixN2Meta } from "/app/pages/index/articles/index.vue?macro=true";
import { default as articlesAYV00TtzjmMeta } from "/app/pages/index/articles.vue?macro=true";
import { default as auth_45without_452faN0Lpz2sRsCMeta } from "/app/pages/index/auth-without-2fa.vue?macro=true";
import { default as indexc0roNMv6fuMeta } from "/app/pages/index/auth/index.vue?macro=true";
import { default as recoveryvl4uqVMYwdMeta } from "/app/pages/index/auth/recovery.vue?macro=true";
import { default as sign_45inDhpkfJgUGVMeta } from "/app/pages/index/auth/sign-in.vue?macro=true";
import { default as sign_45upaE8TCMISRtMeta } from "/app/pages/index/auth/sign-up.vue?macro=true";
import { default as authMZ406IueD8Meta } from "/app/pages/index/auth.vue?macro=true";
import { default as dashboardI7vaEFixFyMeta } from "/app/pages/index/dashboard.vue?macro=true";
import { default as email_45verification0Fx5BuM2bYMeta } from "/app/pages/index/email-verification.vue?macro=true";
import { default as indexsPXYz55e6BMeta } from "/app/pages/index/game/index.vue?macro=true";
import { default as settingsGqxWcy068XMeta } from "/app/pages/index/game/settings.vue?macro=true";
import { default as index3J5r3Sf7qAMeta } from "/app/pages/index/index.vue?macro=true";
import { default as changeeBkQkDY7VpMeta } from "/app/pages/index/interests/change.vue?macro=true";
import { default as indexiDGroJGq3bMeta } from "/app/pages/index/interests/index.vue?macro=true";
import { default as packsBj5WxaiRZOMeta } from "/app/pages/index/interests/packs.vue?macro=true";
import { default as archive51Hhm5HYZcMeta } from "/app/pages/index/interests/results/archive.vue?macro=true";
import { default as compareFgV6rOuLTXMeta } from "/app/pages/index/interests/results/compare.vue?macro=true";
import { default as results6d0K1lO4KXMeta } from "/app/pages/index/interests/results.vue?macro=true";
import { default as interestsy2BvP4tt8KMeta } from "/app/pages/index/interests.vue?macro=true";
import { default as inviteSFJeD54KkpMeta } from "/app/pages/index/invite.vue?macro=true";
import { default as profileZxBGWFYWGgMeta } from "/app/pages/index/profile.vue?macro=true";
import { default as _91id_93uBqXt8vspFMeta } from "/app/pages/index/tasks/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: indexN6pT4Un8hYMeta?.name,
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-about-fants",
    path: "about-fants",
    component: () => import("/app/pages/index/about-fants.vue").then(m => m.default || m)
  },
  {
    name: articlesAYV00TtzjmMeta?.name,
    path: "articles",
    component: () => import("/app/pages/index/articles.vue").then(m => m.default || m),
    children: [
  {
    name: "index-articles-slug",
    path: ":slug()",
    component: () => import("/app/pages/index/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index-articles",
    path: "",
    component: () => import("/app/pages/index/articles/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index-auth-without-2fa",
    path: "auth-without-2fa",
    component: () => import("/app/pages/index/auth-without-2fa.vue").then(m => m.default || m)
  },
  {
    name: authMZ406IueD8Meta?.name,
    path: "auth",
    component: () => import("/app/pages/index/auth.vue").then(m => m.default || m),
    children: [
  {
    name: "index-auth",
    path: "",
    component: () => import("/app/pages/index/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "index-auth-recovery",
    path: "recovery",
    component: () => import("/app/pages/index/auth/recovery.vue").then(m => m.default || m)
  },
  {
    name: "index-auth-sign-in",
    path: "sign-in",
    component: () => import("/app/pages/index/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "index-auth-sign-up",
    path: "sign-up",
    component: () => import("/app/pages/index/auth/sign-up.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index-dashboard",
    path: "dashboard",
    component: () => import("/app/pages/index/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "index-email-verification",
    path: "email-verification",
    component: () => import("/app/pages/index/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "index-game",
    path: "game",
    component: () => import("/app/pages/index/game/index.vue").then(m => m.default || m)
  },
  {
    name: "index-game-settings",
    path: "game/settings",
    component: () => import("/app/pages/index/game/settings.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "",
    component: () => import("/app/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: interestsy2BvP4tt8KMeta?.name,
    path: "interests",
    meta: interestsy2BvP4tt8KMeta || {},
    component: () => import("/app/pages/index/interests.vue").then(m => m.default || m),
    children: [
  {
    name: "index-interests-change",
    path: "change",
    component: () => import("/app/pages/index/interests/change.vue").then(m => m.default || m)
  },
  {
    name: "index-interests",
    path: "",
    component: () => import("/app/pages/index/interests/index.vue").then(m => m.default || m)
  },
  {
    name: "index-interests-packs",
    path: "packs",
    component: () => import("/app/pages/index/interests/packs.vue").then(m => m.default || m)
  },
  {
    name: "index-interests-results",
    path: "results",
    component: () => import("/app/pages/index/interests/results.vue").then(m => m.default || m),
    children: [
  {
    name: "index-interests-results-archive",
    path: "archive",
    component: () => import("/app/pages/index/interests/results/archive.vue").then(m => m.default || m)
  },
  {
    name: "index-interests-results-compare",
    path: "compare",
    component: () => import("/app/pages/index/interests/results/compare.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "index-invite",
    path: "invite",
    component: () => import("/app/pages/index/invite.vue").then(m => m.default || m)
  },
  {
    name: "index-profile",
    path: "profile",
    meta: profileZxBGWFYWGgMeta || {},
    component: () => import("/app/pages/index/profile.vue").then(m => m.default || m)
  },
  {
    name: "index-tasks-id",
    path: "tasks/:id()",
    component: () => import("/app/pages/index/tasks/[id].vue").then(m => m.default || m)
  }
]
  }
]