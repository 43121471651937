const MESSAGE = 'Тебе здесь делать нечего...';

export default defineNuxtRouteMiddleware(async () => {
  const config = useRuntimeConfig();

  if (
    import.meta.client ||
    config.public.projectEnvironment !== 'demonstration'
  )
    return;

  const token = useCookie('testing_user_token');

  if (config.testingUserToken !== token.value) {
    console.log('Missing testing user token');
    throw createError({ statusCode: 403, statusMessage: MESSAGE });
  }
});
