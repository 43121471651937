import revive_payload_client_nWubPlM9y7 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_gexxhtkgafajd3svix6dc5znem/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Sk2spuy804 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_gexxhtkgafajd3svix6dc5znem/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Su9vIbwof8 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_gexxhtkgafajd3svix6dc5znem/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7Uptj6tj5V from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@22.5.0_sass@1.77._h5bbmvb44po7cxv7zl2dt6aalq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_dZBa4krVoY from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_gexxhtkgafajd3svix6dc5znem/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_B6aJ2Hnxcw from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_gexxhtkgafajd3svix6dc5znem/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_s1eJjue9Cv from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_gexxhtkgafajd3svix6dc5znem/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FppAW5BYxG from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_gexxhtkgafajd3svix6dc5znem/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Z0cQRPeVZC from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_QgOvIwkVlC from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_gexxhtkgafajd3svix6dc5znem/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_tQlrNQJIHG from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@22.5.0_sass@1.77.8__fixjx5tyn3gpytmsdl3li266la/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_jqvEN4N1hO from "/app/node_modules/.pnpm/@primevue+nuxt-module@4.0.5_@babel+parser@7.25.4_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_miEWVUBYjs from "/app/node_modules/.pnpm/nuxt-strict-fetch@0.7.2_magicast@0.3.4_rollup@4.21.0/node_modules/nuxt-strict-fetch/dist/runtime/plugin.js";
import switch_locale_path_ssr_2d2o3vl2WO from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_gB2uJ5DePz from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import autoAnimate_4HQGapz9xs from "/app/plugins/autoAnimate.ts";
import request_8cwBTcUfTa from "/app/plugins/request.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import showOnVisible_MqBxBtg92A from "/app/plugins/showOnVisible.ts";
export default [
  revive_payload_client_nWubPlM9y7,
  unhead_Sk2spuy804,
  router_Su9vIbwof8,
  _0_siteConfig_7Uptj6tj5V,
  payload_client_dZBa4krVoY,
  navigation_repaint_client_B6aJ2Hnxcw,
  check_outdated_build_client_s1eJjue9Cv,
  chunk_reload_client_FppAW5BYxG,
  plugin_vue3_Z0cQRPeVZC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QgOvIwkVlC,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_tQlrNQJIHG,
  primevue_plugin_egKpok8Auk,
  plugin_client_jqvEN4N1hO,
  plugin_miEWVUBYjs,
  switch_locale_path_ssr_2d2o3vl2WO,
  i18n_gB2uJ5DePz,
  autoAnimate_4HQGapz9xs,
  request_8cwBTcUfTa,
  sentry_client_shVUlIjFLk,
  showOnVisible_MqBxBtg92A
]